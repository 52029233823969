@use '../abstract/mixins'as mixin;
@use "../abstract/responsive"as rwd;

$c-astronaut: #2B2D77;
$c-east-bay: #4C4D8A;
$c-mine-shaft: #333333;
$c-white: #FFFFFF;
$c-limeade: #39B100;
$c-president-bg-color: #2A357E;
$c-cardinal: #D31E3D;
$c-shiraz: #B30E2B;
$c-prezydent-kimberly: #7173A4;
$c-wild-watermelon: #FF4F6D;

$services-bg-c: $c-astronaut;
$services-sec-c: $c-east-bay;
$body-color: $c-mine-shaft;

$transition: all .4s ease;
$transition-slow: all .6s ease;

//President section
.servicesPromoBox {
  background-color: var(--services-promo-box-bg);
  align-items: center;
  @include mixin.marginTopBottom();
  @include mixin.paddingTopBottom();
  @include mixin.sliderLoader("[data-loader-slider]");

  @include rwd.breakpoint(phonesUp) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @include rwd.breakpoint(phonesDown) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @include rwd.breakpoint(mobile) {
    flex-direction: column-reverse;
  }

  .editAcmsSection {
    left: 30px;
  }

  &__content {
    align-items: flex-start;

    @include rwd.breakpoint(mobile) {
      flex-direction: column-reverse;
    }
  }

  .topBar {
    @include rwd.breakpoint(mobileUp) {
      margin-left: 20px;
    }

    &.projectsSliderAlone {
      margin-left: 0;

      @include rwd.breakpoint(phonesDown) {
        margin-left: 10px;
      }
    }

    @include rwd.breakpoint(mobile) {
      display: none;

      &.projectsSliderAlone {
        display: block;

        .sectionTitle {
          margin-bottom: 0;
        }
      }
    }
  }

  .sectionTitle,
  .sectionTitle a {
    color: $c-white;

    @include rwd.breakpoint(mobile) {
      margin-bottom: 5px;
    }

    a {

      &:hover,
      &:focus {
        @include rwd.breakpoint(smallUp) {
          color: $c-white;
        }
      }

      &:after {
        background-color: $c-white;
      }
    }
  }

  .rightSide {
    @include rwd.breakpoint(large) {
      display: flex;
      justify-content: center;
    }

    @include rwd.breakpoint(mobileUp) {
      z-index: 1;
    }

    @include rwd.breakpoint(smalltablet) {
      padding-left: 5%;
    }

    &__content {
      display: flex;

      @include rwd.breakpoint(smalltablet) {
        max-width: 200px;
      }

      @include rwd.breakpoint(mobileUp) {
        justify-content: space-between;
        flex-direction: column;
        margin-bottom: 24px;
        padding-right: 30px;
      }

      @include rwd.breakpoint(mobile) {
        justify-content: space-between;
      }

      @include rwd.breakpoint(largePhones) {
        padding: 0 30px;
      }

      @include rwd.breakpoint(phonesDown) {
        padding: 0 15px;
      }

      @media screen and (max-width: 379px) {
        padding: 0;
      }

      .logoLnk {
        @include rwd.breakpoint(mobileUp) {
          margin-bottom: 30px;
        }

        @include rwd.breakpoint(smallPhones) {
          margin-right: 20px;
          display: flex;
        }

        img {
          max-width: 160px;
          height: auto;

          @include rwd.breakpoint(smallPhones) {
            max-width: 128px;
          }

          @media screen and (max-width: 379px) {
            max-width: 100px;
          }
        }

        &.differentMobileImg {
          @include rwd.breakpoint(mobileUp) {
            .mobileImg {
              display: none;
            }
          }

          @include rwd.breakpoint(mobile) {
            .decktopImg {
              display: none;
            }
          }
        }
      }

      .lnkBtn {
        @include mixin.font(16px, 24px, 600, $c-white);
        display: block;
        padding: 5px 10px;
        border-radius: 5px;
        background-color: transparent;
        text-decoration: none;
        transition: $transition;
        letter-spacing: 0.5px;
        white-space: nowrap;
        text-align: center;
        max-width: 210px;
        border: 2px solid var(--services-promo-box-right-color);

        @include rwd.breakpoint(mobileUp) {
          margin-top: 20px;
        }

        @include rwd.breakpoint(smallPhones) {
          @include mixin.font(14px, 18px, 400);
          padding: 5px;
        }

        @media screen and (max-width: 379px) {
          font-size: 14px;
        }

        &:hover,
        &:focus {
          @include rwd.breakpoint(smallUp) {
            border-color: $c-white;
            background-color: $c-white;
            color: $body-color;
          }
        }
      }
    }

    &__wrapper {
      @include rwd.breakpoint(mobile) {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
      }
    }

    .rightImg {
      flex: 1;
      height: 100%;
      object-fit: contain;
      max-width: 190px;
      max-height: 260px;

      @include rwd.breakpoint(desktopsDown) {
        display: none;
      }

      @media screen and (min-width: 1600px) {
        max-width: 235px;
      }
    }
  }

  .lightTxt {
    color: var(--main-color);
    font-weight: 700;
  }

  .projectsSlider {
    @include rwd.breakpoint(large) {
      padding-bottom: 30px;
    }

    &:not([data-projects-slider-alone]) {
      @include rwd.breakpoint(mobileUp) {
        padding: 0 60px 0 20px;
      }

      .slideNumber {
        color: $c-white;
      }
    }

    @include rwd.breakpoint(mobile) {
      margin-top: 30px;
    }

    @include rwd.breakpoint(phonesDown) {
      .splide__track {
        margin-right: -15px;
      }
    }

    &[data-projects-slider-alone] {
      .splide__arrow {
        &--next {
          right: -20px;
        }

        &--prev {
          left: -20px;
        }
      }

      &.arrowsMobileSlider {
        @include rwd.breakpoint(phonesDown) {
          .splide__arrow {
            &--next {
              right: 15px;
            }

            &--prev {
              left: 15px;
            }
          }
        }
      }
    }

    .promoSingleBox {
      @include rwd.breakpoint(phonesDown) {
        height: 100%;
      }
    }

    .splide__arrow {
      top: 30%;

      &--next {
        @include rwd.breakpoint(mobileUp) {
          right: 25px;
        }

        @include rwd.breakpoint(phonesDown) {
          right: 0;
        }
      }

      &--prev {
        @include rwd.breakpoint(mobileUp) {
          left: -20px;
        }

        @include rwd.breakpoint(phonesDown) {
          left: 0;
        }
      }

      &:hover,
      &:focus {
        @include rwd.breakpoint(smallUp) {
          &:before {
            color: $c-white;
          }
        }
      }
    }

    .boxLnk {
      display: block;
      color: $c-white;
      text-decoration: none;

      &:hover,
      &:focus {
        @include rwd.breakpoint(smallUp) {
          text-decoration: none;

          img {
            transform: scale(1.1);
          }

          .boxTitle {
            text-decoration: underline;
          }
        }
      }
    }

    .imgWrapper {
      overflow: hidden;
      position: relative;

      img {
        transition: $transition-slow;
        transform: scale(1);
        width: 100%;
        object-fit: cover;

        @include rwd.breakpoint(smallPhones) {
          max-height: 200px;
        }

        @media screen and (max-width: 379px) {
          max-height: 160px;
        }
      }
    }

    .boxContent {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
    }

    .boxTitle {
      @include mixin.font(18px, 26px, 600);
      @include mixin.showLines(2);
      max-height: 81px;
      transition: $transition;
      flex: 1;
      margin-bottom: 0;

      @include rwd.breakpoint(mobile) {
        @include mixin.showLines(2);
      }

      @include rwd.breakpoint(largePhones) {
        margin-top: 10px;
      }
    }

    .progressBox {
      @include mixin.position(absolute, false, 14px, 12px);
      margin: 0;
      width: 50px;
      height: 50px;
      z-index: 3;

      @include rwd.breakpoint(mobile) {
        width: 50px;
        height: 50px;
      }

      @include rwd.breakpoint(largePhones) {
        margin-top: 10px;
      }

      &:after {
        @include mixin.makeCircle(61px, rgba(11, 26, 58, 0.6));
        @include mixin.position(absolute, 48%, false, false, 48%);
        content: "";
        z-index: -1;
        transform: translate(-50%, -50%);
      }

      &:before {
        @include mixin.makeCircle(48px);
        @include mixin.position(absolute, 0, false, false, 0);
        content: "";
        border: 2px solid $c-white;
      }

      .round {
        width: 48px;
        height: 48px;
        stroke-width: 6px;
        stroke: var(--main-color);

        &.none {
          stroke: transparent;
        }

        &.full {
          stroke: $c-limeade;
        }
      }

      .circles-text,
      .value {
        @include mixin.font(13px, 13px);
        @include mixin.position(absolute, 62%, false, false, 50%);
        transform: translate(-45%, -55%);
        letter-spacing: 0.5px;

        @include rwd.breakpoint(mobile) {
          @include mixin.font(14px, 14px);
        }
      }

      .progressDesc {
        @include mixin.font(8px, 9px, 400, $c-white);
        @include mixin.position(absolute, 34%, false, false, 48%);
        font-family: "Roboto", sans-serif;
        transform: translate(-45%, -55%);
      }
    }

    .withCategoryLabel {
      img {
        @include rwd.breakpoint(smallUp) {
          height: 185px;
        }
      }
    }
  }

  .socialMedia {
    &__list {
      @include mixin.flexCenter;

      @include rwd.breakpoint(mobile) {
        margin-top: 8px;
      }

      @include rwd.breakpoint(phonesDown) {
        display: none;
      }

      li {
        &:first-of-type {
          .socialMedia__lnk {
            margin-left: 0;
          }
        }

        &:last-of-type {
          .socialMedia__lnk {
            margin-right: 0;
          }
        }
      }
    }

    &__lnk {
      @include mixin.flexCenter();
      height: 30px;
      width: 30px;
      margin: 0 8px;

      @include rwd.breakpoint(smallPhones) {
        height: 20px;
        width: 20px;
      }

      .icon {
        @include mixin.font(25px, 25px, false, var(--services-promo-box-right-color));
        transition: $transition;

        @include rwd.breakpoint(mobile) {
          font-size: 20px;

          &.twitter {
            font-size: 20px;
          }
        }

        &.linkedin {
          @include rwd.breakpoint(mobileUp) {
            font-size: 22px;
          }
        }

        &.twitter {
          font-size: 21px;
        }
      }

      &:hover {
        @include rwd.breakpoint(smallUp) {
          .icon {
            margin-bottom: 10px;
            color: $c-white;
          }
        }
      }
    }
  }

  .splide__pagination {
    bottom: 0;
    margin-top: 35px;
  }

  .splide.arrowsLight .splide__arrow::before {
    color: var(--services-promo-box-details-color);
  }

  .splide__pagination__page.is-active {
    border-top-color: var(--services-promo-box-details-color);
  }

  .withMobileLink {
    @include rwd.breakpoint(mobile) {
      .slideNumber {
        display: none;
      }
    }
  }

  .mobileLinkBox {
    text-align: center;
    margin-top: 20px;

    @include rwd.breakpoint(mobileUp) {
      display: none;
    }

    .mobileLink {
      font-weight: 700;
      color: $c-white;
      border: solid var(--main-color) 2px;
      padding: 8px 25px;
      display: inline-block;
      border-radius: 5px;
      background-color: transparent;
      transition: all 0.4s ease;
      text-decoration: none;
    }
  }

  // HOTFIX - WCAG  - zdublowanie kodu do wyswietlenia na mobile,
  // aby kolejnosc taba przy uzyciu klawiatury była prawidłowa
  .showOnMobile {
    @include rwd.breakpoint(phonesUp) {
      display: none;
    }

    @include rwd.breakpoint(phonesDown) {
      align-items: center;
    }
  }

  .hideOnMobile {
    @include rwd.breakpoint(phonesDown) {
      display: none;
    }
  }

  &.rightSideSmall {
    .servicesPromoBox__content {
      align-items: center;
    }

    .rightSide {
      padding-left: 0;
    }

    .rightSide__content {
      padding-right: 0;
      max-width: unset;

      .logoLnk img {
        max-width: unset;
      }
    }
  }

  &.goWroView {
    @include rwd.breakpoint(mobile) {
      .topBar {
        display: block;
      }

      .rightSide__content {
        display: none;
      }

      .projectsSlider {
        margin-top: 10px;
      }

      .mobileLink {
        color: inherit;
      }

      .splide__arrow {
        bottom: -80px;
      }
    }

    @include rwd.breakpoint(phonesDown) {
      .projectsSlider .imgWrapper img {
        height: 190px;
      }
    }

    @include rwd.breakpoint(large) {
      .projectsSlider .imgWrapper img {
        height: 200px;
      }
    }
  }

  // strona główna prezydent - spotkania osiedlowe
  &.presidentsMeetings {
    background-color: $c-president-bg-color;

    @include rwd.breakpoint(large) {
      background-image: url(https://www.wroclaw.pl/prezydent-wroclawia/files/news/18023/sutryk-photo-meetings.png);
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: auto 100%;
      background-size: contain;
    }

    @include rwd.breakpoint(mobile) {
      padding-left: 40px;
      padding-right: 40px;
    }

    @include rwd.breakpoint(phonesDown) {
      padding-left: 15px;
      padding-right: 15px;
    }

    .topBar {
      @include rwd.breakpoint(mobile) {
        display: block;
      }

      .sectionTitle {
        color: $c-white;

        @include rwd.breakpoint(mobile) {
          margin-bottom: 10px;
        }

        a {
          color: $c-white;

          @include rwd.breakpoint(mobile) {
            margin-bottom: 10px;
          }

          &:hover,
          &:focus {
            @include rwd.breakpoint(smallUp) {
              color: $c-white;
            }
          }

          &:after {
            background-color: $c-white;
          }
        }
      }
    }

    .projectsSlider {
      @include rwd.breakpoint(mobile) {
        margin-top: 0;
      }
    }

    .presidentsMeetingsColl {
      @include rwd.breakpoint(phonesDown) {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    .presidentsMeetingsBoxLable {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 4px 6px;
      text-align: center;
      background-color: $c-white;

      .presidentsMeetingsLable {
        @include mixin.font(18px, 26px, 600, $c-president-bg-color);
        transition: $transition;
        text-decoration: none;

        @media screen and (min-width: 576px) and (max-width: 991px) {
          font-size: 15px;
          line-height: 18px;
        }
      }
    }

    .projectsSlider .boxTitle {
      color: $c-white;
    }

    .presidentsMeetingsBoxTitle {

      .presidentsMeetingsTitle {
        margin-top: 6px;
        margin-bottom: 6px;
        @include mixin.font(18px, 26px, 600, $c-white);
        @include mixin.showLines(2);
        transition: $transition;
        text-decoration: none;

        @media screen and (min-width: 576px) and (max-width: 991px) {
          font-size: 15px;
          line-height: 18px;
        }
      }
    }

    .presidentsMeetingsDetailsEvent {
      display: flex;
      flex-wrap: wrap;

      .presidentsMeetingsTime,
      .presidentsMeetingsPlace {
        margin-top: 10px;
        @include mixin.font(16px, 18px, 300, $c-white);
        @include mixin.showLines(1);

        @include rwd.breakpoint(mobile) {
          font-size: 15px;
        }

        >i {
          margin-right: 8px;
          display: inline-block;
          width: 14px;
          height: 14px;
          font-size: 14px;
          color: $c-wild-watermelon;
        }
      }

      .presidentsMeetingsTime {
        margin-right: 12px;
      }
    }

    .rightSide__content {

      @include rwd.breakpoint(largePhones) {
        padding: 0;
        margin-bottom: 16px;
        align-items: center;
      }

      @include rwd.breakpoint(phonesDown) {
        padding: 0;
        justify-content: center;
      }

      .logoLnk {
        @include rwd.breakpoint(largePhones) {
          display: block;

          img {
            max-width: 120px;
          }
        }

        @include rwd.breakpoint(phonesDown) {
          display: none;
        }
      }

      .lnkBtn {
        letter-spacing: 0;

        @include rwd.breakpoint(mobileUp) {
          border-color: $c-cardinal;
          background-color: $c-cardinal;
        }

        @include rwd.breakpoint(mobile) {
          margin-top: 20px;
          border-color: $c-prezydent-kimberly;
          background-color: transparent;
        }

        @include rwd.breakpoint(tablet) {
          font-size: 14px;
        }

        &:hover,
        &:focus {
          @include rwd.breakpoint(smallUp) {
            border-color: $body-color;
            background-color: $c-white;
            color: $body-color;
          }
        }
      }
    }

    .rightSide .rightImg {
      opacity: 0;
    }

    .arrowsMobileSlider {
      .slideNumber {
        @include rwd.breakpoint(phonesDown) {
          display: none;
        }
      }

      .splide__arrow {
        bottom: -70px;
      }
    }
  }
}